.PaseoComponente {
  background-color: rgba(253, 253, 253, 0.7);
  border-radius: 15px;
  width: 64.93%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
}

.CiudadesDiv {
  margin: 20px;
  color: white;
}

.CiudadesDiv h3 {
  text-shadow: 1px 1px 7px #000000a9;
}

.AllPasseos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PaseoDiv {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20rem;
  height: 17rem;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.4);
}
.PaseoDivPaquete {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 45rem;
  height: 17rem;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.4);
}
.H4Component {
  text-shadow: 0px 1px 7px rgb(0, 0, 0);
  position: absolute;
  margin-top: 15rem;
}

.LinkPasseio {
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 7px #000000;
}
.LinkPasseio :hover {
  text-decoration: underline;
  color: white;
}

@media screen and (max-width: 600px) {
  .PaseoDiv {
    width: 18rem;
    height: 10rem;
  }
  .PaseoDivPaquete {
    width: 18rem;
    height: 10rem;
  }
  .PaseoComponente {
    width: 95%;
  }
  .H4Component {
    margin-top: 0rem;
  }
}
