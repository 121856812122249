.ImageDiv {
  max-width: 6rem;
}

@media screen and (max-width: 600px) {
  .Container-Essencia {
    width: 95%;
  }
  .Main-Essencia {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }
  .ImageDiv {
    width: 100%;
    max-width: none;
  }
  .Text-Essencia {
    width: 100%;
  }
}
