.HomeContainer {
  margin-top: -40px;
  margin-bottom: 30px;
}

#OutlineArrow {
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 50%;
  animation: shadow-scale 1.5s ease-in-out infinite alternate;
}

@keyframes shadow-scale {
  0% {
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.HomeContainer div {
  margin-top: 20px;
}

.Text-Box-Nossavida {
  width: 65%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 30px auto;
  background-color: #57b9ff;
  color: white;
  border-radius: 15px;
  margin-top: 40px;
}
.NossaVida {
  font-size: 220%;
  letter-spacing: 5px;
  text-shadow: 1px 1px 7px #000000;
}
.Text-Box-Nossavida .Regiao {
  display: flex;
  justify-content: space-evenly;
}
.Text-Box-Nossavida .Regiao h2 {
  font-size: 2.7rem;
}
.Text-Box-Nossavida .Regiao .Ciudades {
  display: flex;
  align-items: baseline;
  font-size: 1.7rem;
  text-shadow: 1px 1px 7px #000000;
}

.Box-nos {
  display: flex;
  justify-content: space-evenly;
  margin: 50px 4%;
  color: white;
}

.Box h3 {
  text-shadow: 0px 1px 7px rgb(0, 0, 0);
}

.Link-Box_Double {
  text-decoration: none;
  color: white;
}

.Box {
  width: 25%;
  height: 16rem;
  background-color: #227b2b;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Box-Essencia {
  background-color: #b1ffb9;
}

.Text-Box-Nossahistoria h2 {
  font-size: 250%;
  letter-spacing: 2px;
  color: white;
  text-shadow: 1px 1px 10px #000000;
}

@media screen and (max-width: 600px) {
  .Text-Box-Nossavida {
    width: 95%;
    padding: 20px;
  }
  .NossaVida {
    font-size: 130%;
    letter-spacing: 3px;
    margin-bottom: 1rem;
  }
  .Regiao {
    flex-direction: column;
    align-items: center;
  }
  .Text-Box-Nossahistoria {
    width: 95%;
    margin: auto;
  }
  .Box-nos {
    flex-direction: column;
    align-items: center;
  }
  .Box {
    width: 80%;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.37);
  }
}
