.HomeDiv {
  display: flex;
  align-items: center;
  width: 64.93%;
  height: 40.43%;
  margin: auto;
  justify-content: center;
}

.custom-carousel-item img {
  border-radius: 15px;
  width: 100%;
}
.PresentDiv {
  height: 600px;
}
.ArrowNext {
  left: 75.4%;
  font-size: 4.5rem;
  position: absolute;
  fill: rgb(0, 0, 0);
}
.ArrowPrev {
  left: 21%;
  font-size: 4.5rem;
  position: absolute;
  fill: rgb(0, 0, 0);
}
.fade-in-out {
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .HomeDiv {
    width: 95%;
  }
}
