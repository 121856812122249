body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateX(-100%);
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  background-image: url("./Images/BackGround.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Añadir bordes redondeados */
}

/* Ocultar el scrollbar cuando no se esté utilizando */
body::-webkit-scrollbar-thumb:vertical {
  height: 20px; /* Ajusta la altura del thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Añadir una transición suave al hover */
body::-webkit-scrollbar-thumb {
  transition: background 0.3s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
