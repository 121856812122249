@font-face {
  font-family: "Architects Daughter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTYlg4w.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.Nav {
  width: 100%;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.37);
  background-color: #00abf4;
  font-family: "Architects Daughter";
  font-size: 1.5rem;
  padding: 5px 25px;
  text-shadow: 1px 1px 7px #000000;
  margin-bottom: 10px;
}

.UlNav {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 20px;
}

.LiNav {
  border-bottom: 1px rgb(62, 168, 255) solid;
  margin-left: 60px;
}

.DivLogo h2 {
  font-size: 25px;
  margin: 0;
  color: white;
}

.h2Logo {
  position: absolute;
  top: 1.7rem;
  left: 9rem;
}

.ANav {
  text-decoration: none;
  color: white;
  font-size: 25px;
}
.ANav img {
  position: absolute;
  top: -0.9rem;
  left: 1.5rem;
  width: 7rem;
}

@media screen and (max-width: 600px) {
  .Nav {
    width: 95%;
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.37);
    background-color: #00abf4;
    font-family: "Architects Daughter";
    font-size: 1rem;
    padding: 1px 25px;
    border-radius: 10px;
    margin: 15px auto;
    text-shadow: 1px 1px 7px #000000;
  }

  .UlNav {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
  }

  .DivLogo img {
    width: 6rem;
    top: -0.8rem;
    left: 1rem;
  }
  .DivLogo h2 {
    display: none;
  }

  .ANav {
    font-size: 1.15rem;
  }
}
