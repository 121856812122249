.App {
  text-align: center;
}

@media screen and (max-width: 600px) {
}

#loader {
  background-color: rgb(75, 75, 75);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 10rem;
  margin: 36vh auto;
  border-radius: 20px;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 1s linear infinite;
}
