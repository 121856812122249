.PacoteDiv {
  background-color: #2da0ff;
  padding: 2rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 65%;
  min-height: 50rem;
  margin: 50px auto;
  justify-content: space-evenly;
}
.PacoteH2 {
  letter-spacing: 5px;
  background-color: aliceblue;
  width: 85%;
  margin: 5px auto;
  padding: 5px;
  border-radius: 15px;
}
.PacoteDesc {
  padding: 10px;
  margin: 10px auto;
  width: 65%;
  background-color: aliceblue;
  border-radius: 15px;
}
.PacoteInfo {
  padding: 20px;
  margin: 10px auto;
  width: 95%;
  background-color: aliceblue;
  border-radius: 15px;
}

@media screen and (max-width: 600px) {
  .PacoteDiv {
    width: 95%;
  }
  .PacoteDesc {
    width: 95%;
  }
  .PacoteInfo {
    width: 95%;
  }
}
