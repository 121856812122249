.Roteiro::-webkit-scrollbar {
  width: 8px;
}

.Roteiro::-webkit-scrollbar-track {
  background: #f1f1f115;
}

.Roteiro::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Añadir bordes redondeados */
}

/* Ocultar el scrollbar cuando no se esté utilizando */
.Roteiro::-webkit-scrollbar-thumb:vertical {
  height: 20px; /* Ajusta la altura del thumb */
}

.Roteiro::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Añadir una transición suave al hover */
.Roteiro::-webkit-scrollbar-thumb {
  transition: background 0.3s ease-in-out;
}

.DetailDiv {
  background-color: #57b9ff;
  width: 65%;
  margin: auto;
  border-radius: 15px;
  padding: 25px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  margin-top: 20px;
}

.DetailTitle {
  letter-spacing: 3px;
  font-size: 3.5rem;
}

.EdadesDiv {
  display: flex;
  justify-content: space-around;
  background-color: #3c82b4;
  width: 75%;
  margin: auto;
  padding: 20px;
  align-items: center;
  border-radius: 15px;
}

.Roteiro {
  gap: 5%;
  width: 75%;
  background-color: #3c82b4;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
}

.Embarque {
  margin: 0;
  font-size: 20px;
}

.Titles {
  padding: 0 13%;
  display: flex;
  margin-top: 2%;
}

.RoteiroMap {
  background-color: rgba(255, 255, 255, 0.85);
  width: 85%;
  color: #000;
  padding: 10px;
  margin: 10px auto;
  border-radius: 15px;
}

@media screen and (max-width: 600px) {
  .DetailDiv {
    width: 95%;
  }
  .DetailTitle {
    letter-spacing: 1px;
    font-size: 2.5rem;
  }
  .EdadesDiv {
    width: 100%;
    flex-direction: column;
    gap: 1.2rem;
  }
  .Titles {
    padding: 0;
  }
  .Roteiro {
    width: 100%;
  }
}
