.Container-Essencia {
  width: 65%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.Main-Essencia {
  display: flex;
  background-color: rgb(114, 114, 114);
  margin: 50px 0;
  padding: 25px;
  border-radius: 15px;
  align-items: center;
}

.ImageDiv {
  width: 60rem;
  min-width: 25%;
  height: 15rem;
  border-radius: 15px;
}

.Text-Essencia {
  max-width: 80%;
}
