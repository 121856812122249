.FooterDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #00abf4;
  color: white;
}
.FirtsDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.ImgDiv img {
  background-color: #00abf4;
  width: 6rem;
  bottom: 0.5rem;
  left: 1rem;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.582);
}
.IconsFooter {
  display: flex;
  flex-direction: row;
  margin-left: 62px;
  gap: 1rem;
  font-size: 1.2rem;
  align-items: flex-start;
  padding: 1rem;
}
.IconsFooter a {
  margin: 0 20px;
  color: white;
  text-decoration: none;
  text-shadow: 0px 0px 4px black;
}

@media screen and (max-width: 600px) {
  .FooterDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    background-color: #00abf4;
    color: white;
  }
  .FirtsDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .ImgDiv img {
    display: none;
  }
  .IconsFooter {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    gap: 1px;
    font-size: 12px;
    align-items: flex-start;
    padding: 10px;
  }
  .IconsFooter a {
    margin: 0 20px;
    color: white;
    text-decoration: none;
  }
}
